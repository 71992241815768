<template>
  <v-skeleton-loader
    v-if="!!loading"
    tile
    loading
    class="mx-auto"
    type="card"
    elevation="5"
    boilerplate
  />
  <v-container v-else class="pa-0">
    <v-dialog v-model="dialog" width="800">
      <v-card width="800" height="800" class="d-flex align-center">
        <figure v-if="files == null">
          <img
            style="object-fit: cover; width: 100%; height: 100%"
            :src="partner.logoImage"
            :alt="`logotipo da empresa ${partner.name}`"
          />
        </figure>
        <figure v-else>
          <img
            style="object-fit: cover; width: 100%; height: 100%"
            :src="avatarPhoto"
            :alt="`logotipo da empresa ${partner.name}`"
          />
        </figure>
      </v-card>
    </v-dialog>

    <header class="d-flex mb-4">
      <TitleVue title="Editar dados" />
    </header>

    <v-tabs v-model="tab" class="mt-4">
      <v-tab>Dados Principais</v-tab>
      <v-tab>Endereço</v-tab>
      <v-tab>Dados Bancários</v-tab>
      <v-tab>Horários</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item class="spacing">
        <v-card class="px-5 pt-3">
          <v-row>
            <v-col cols="12" md="2" class="mt-2 col-element">
              <label id="input-element">
                <v-file-input
                  label="Alterar logo"
                  v-model="files"
                  @change="handleConvertForBase64"
                  accept="image/*"
                  class="input"
                  id="input-file"
                  append-icon="mdi-close"
                />
                <v-avatar size="117" class="cursor-alter" v-if="partner.logoImage !== ''" rounded>
                  <img
                    v-if="files == null"
                    style="object-fit: cover; width: 117px; height: 117px"
                    :src="partner.logoImage"
                    :alt="`logotipo da empresa ${partner.name}`"
                  />
                  <img
                    style="object-fit: cover; width: 117px; height: 117px"
                    :src="avatarPhoto"
                    :alt="`logotipo da empresa ${partner.name}`"
                    v-else
                  />
                </v-avatar>
                <v-avatar v-else size="117" color="#e5183d">
                  <v-icon color="white" size="117" dark>mdi-account-circle</v-icon>
                </v-avatar>

                <p class="text-center text-alterar-logo">Alterar logo</p>
              </label>
            </v-col>
            <v-col cols="12" md="4" align-self="end">
              <v-text-field
                :label="partner.typeCompany == 'PJ' ? 'Razão social' : 'Nome completo'"
                v-model="partner.name"
              />
            </v-col>
            <v-col cols="12" md="4" align-self="end">
              <v-text-field
                :label="partner.typeCompany == 'PJ' ? 'Nome fantasia' : 'Como quer ser chamado'"
                v-model="partner.nickname"
              />
            </v-col>
            <v-col cols="12" md="2" align-self="end">
              <v-text-field
                :label="partner.typeCompany == 'PJ' ? 'CNPJ' : 'CPF'"
                v-model="partner.document"
                v-mask="partner.typeCompany == 'PJ' ? '##.###.###/####-##' : '###.###.###-##'"
              />
            </v-col>
            <v-col cols="12" md="4" v-if="partner.typeCompany === 'PJ'">
              <v-text-field label="CNAE" v-model="partner.cnaeCode" />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field label="Email" v-model="partner.email" />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field label="Telefone" v-model="partner.phone" v-mask="'(##) #####-####'" />
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                label="Categoria"
                :items="categories"
                item-text="name"
                item-value="id"
                v-model="partner.categoryId"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card class="px-5 pt-3">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                @blur="handleAddress"
                label="CEP"
                v-model="partner.address.zipCode"
                v-mask="'#####-###'"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field label="Logradouro" v-model="partner.address.street" />
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field label="Número" v-model="partner.address.addressNumber" />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field label="Bairro" v-model="partner.address.neighborhood" />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field label="Complemento" v-model="partner.address.complement" />
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                label="Cidade"
                :required="true"
                :disabled="true"
                :readonly="true"
                v-model="partner.address.cityName"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                label="Estado"
                :required="true"
                :disabled="true"
                :readonly="true"
                v-model="partner.address.state"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card class="px-5 pt-3">
          <v-row>
            <v-col cols="12" md="2">
              <v-text-field label="Documento do titular" v-model="partner.digitalAccountDocument" />
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field label="Número da conta" v-model="partner.digitalAccountNumber" />
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field label="Chave PIX" v-model="partner.pixkey" />
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card class="px-5 pt-3">
          <v-row class="mb-4">
            <v-col cols="12" md="3">
              <v-select :items="day" label="Dia" v-model="businessHoursDay" />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                type="time"
                label="Horário de abertura"
                v-model="businessHoursStartTime"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                type="time"
                label="Horário de fechamento"
                v-model="businessHoursEndTime"
              />
            </v-col>
            <v-col cols="12" md="1" class="d-flex align-item-end">
              <v-btn
                :disabled="
                  businessHoursDay == '' ||
                  businessHoursStartTime == '' ||
                  businessHoursEndTime == ''
                "
                icon
                elevation="0"
                color="#FF5726"
                @click="handleAddBusinessHours"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headersHours"
            :items="partner.businessHours"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(item, index) in items" :key="index">
                  <td class="text-center">{{ item.day }}</td>
                  <td class="text-center">{{ item.startTime }}</td>
                  <td class="text-center">{{ item.endTime }}</td>
                  <td class="text-center">
                    <v-btn color="#FF5726" icon @click="handleDeleteBusinessHours(index)">
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card class="px-5 pt-3">
          <v-row>
            <v-col cols="12">
              <v-textarea
                name="input-7-1"
                label="Descrição complementar"
                auto-grow
                :rules="rulesDescricao"
                rows="1"
                no-resize
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Whatsapp"
                prepend-icon="mdi-whatsapp"
                v-mask="'(##) #####-####'"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Instagram"
                prepend-icon="mdi-instagram"
                hint="https://instagram.com/example"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <!-- Ações -->
    <v-card-actions class="d-flex justify-end fixed-footer">
      <v-btn color="#e5183d" class="ml-2" dark width="100" @click="handleUpdatePartner()"
        >Atualizar</v-btn
      >
    </v-card-actions>
  </v-container>
</template>

<script lang="ts">
import TitleVue from "@/components/title/Title.vue";
import { defineComponent } from "vue";
import { PartnerDetailsType } from "@/types/partner/Partner";
import { GetPartner } from "@/services/partners/GetPartner";
import { GetAllCategories } from "@/services/partners/GetAllCategories";
import { UpdatePartner } from "@/services/partners/UpdatePartner";
import { GetAddress } from "@/services/viaCep/GetAddress";
export default defineComponent({
  components: { TitleVue },
  data() {
    return {
      tab: 0,
      rulesDescricao: [(v: string) => v.length <= 255 || "Máximo 255 caracteres"],
      loading: false,
      partner: {
        id: "",
        name: "",
        status: "PENDING",
        nickname: "",
        nickName: "",
        tax: 5,
        typeCompany: "PJ",
        category: "",
        phone: "",
        email: "",
        document: "",
        pixkey: "",
        logoImage: "",
        cnaeCode: "",
        address: {
          state: "",
          street: "",
          addressNumber: "",
          neighborhood: "",
          complement: "",
          zipCode: "",
          cityName: "",
          ibge: "",
        },
        users: [],
        user: {
          name: "",
          email: "",
          phone: "",
          document: "",
          password: "",
          nickname: "",
          birthdate: "",
          motherName: "",
        },
        associate: [],
        businessHours: [],

        createdAt: "",
        digitalAccountNumber: "",
        digitalAccountDocument: "",
        associates: [],
        categoryId: "",
        contractFile: "",
        registrationStatusFile: "",
        partnerConfigs: {
          id: 0,
          onlineSale: false,
          pdvSale: false,
          instantCashback: 0,
          updatedAt: "",
        },
      } as PartnerDetailsType,
      headersHours: [
        {
          text: "Dia",
          align: "center",
          value: "day",
        },
        {
          text: "Abertura",
          align: "center",
          value: "startTime",
        },
        {
          text: "Fechamento",
          align: "center",
          value: "endTime",
        },
        {
          text: "",
          align: "center",
          value: "actions",
        },
      ],
      businessHoursDay: "",
      businessHoursStartTime: "",
      businessHoursEndTime: "",
      day: [
        "Domingo",
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
      ],
      categories: [],
      files: null,
      avatarPhoto: "",
      dialog: false,
      originalLogo: "",
    };
  },
  methods: {
    handleAddBusinessHours() {
      this.partner.businessHours.push({
        day: this.businessHoursDay,
        endTime: this.businessHoursEndTime,
        startTime: this.businessHoursStartTime,
      });
      this.businessHoursDay = "";
      this.businessHoursEndTime = "";
      this.businessHoursStartTime = "";
    },
    handleDeleteBusinessHours(item: any) {
      this.partner.businessHours = this.partner.businessHours.filter(
        (element, index) => index !== item
      );
    },
    async handleGetDataPartner() {
      this.loading = true;
      const getPartner = new GetPartner();
      const partner = await getPartner.execute();

      partner.document.length > 11
        ? (this.partner.typeCompany = "PJ")
        : (this.partner.typeCompany = "PF");
      this.partner.id = partner.id || "";
      this.partner.name = partner.name || "";
      this.partner.nickname = partner.nickname || "";
      this.partner.document = partner.document || "";
      this.partner.cnaeCode = partner.cnaeCode || "";
      this.partner.category = partner.category || "";
      this.partner.categoryId = partner.categoryId || "";
      this.partner.tax = partner.tax || 0;
      this.partner.status = partner.status;
      this.partner.createdAt = partner.createdAt || "";
      this.partner.digitalAccountDocument = partner.digitalAccountDocument || "";
      this.partner.digitalAccountNumber = partner.digitalAccountNumber || "";
      this.partner.pixkey = partner.pixkey || "";
      this.partner.logoImage = partner.logoImage || "";
      this.originalLogo = partner.logoImage || "";
      this.partner.phone = partner.phone || "";
      this.partner.email = partner.email || "";
      this.partner.address.street = partner.address.street || "";
      this.partner.address.ibge = partner.address.ibge;
      this.partner.address.addressNumber = partner.address.addressNumber || "";
      this.partner.address.neighborhood = partner.address.neighborhood || "";
      this.partner.address.complement = partner.address.complement || "";
      this.partner.address.zipCode = partner.address.zipCode || "";
      this.partner.address.cityName = partner.address.cityName || "";
      this.partner.address.state = partner.address.state || "";
      this.partner.user = partner.users[0];
      this.partner.associates = partner.associates || [];
      this.partner.businessHours = partner.businessHours || [];
      this.partner.contractFile = partner.contractFile || "";
      this.partner.registrationStatusFile = partner.registrationStatusFile || "";
      this.partner.partnerConfigs.id = partner.partnerConfigs.id || 0;
      this.partner.partnerConfigs.onlineSale = partner.partnerConfigs.onlineSale || false;
      this.partner.partnerConfigs.pdvSale = partner.partnerConfigs.pdvSale || false;
      this.partner.partnerConfigs.updatedAt = partner.partnerConfigs.updatedAt || "";
      this.partner.partnerConfigs.instantCashback = partner.partnerConfigs.instantCashback || 0;
      this.loading = false;
    },
    async handleGetAllCategories() {
      let categories = new GetAllCategories();
      let dataCategories = await categories.execute();

      this.categories = dataCategories.data;
    },
    async handleAddress() {
      const getAddress = new GetAddress();
      const address = await getAddress.execute(this.partner.address.zipCode);
      if (address) {
        this.partner.address.street = address.logradouro;
        this.partner.address.neighborhood = address.bairro;
        this.partner.address.cityName = address.localidade || "";
        this.partner.address.state = address.uf || "";
        this.partner.address.ibge = address.ibge || "";
      }
    },
    handleConvertForBase64() {
      if (this.files) {
        const reader = new FileReader();
        reader.onload = () => {
          let base64Image = reader.result as string;
          base64Image = base64Image.replace(/^data:[^;]+;base64,/, "");
          this.partner.logoImage = base64Image;
        };
        reader.onerror = (error) => {
          console.error("Error reading the file", error);
        };

        const imageBlob = new Blob([this.files]);
        reader.readAsDataURL(imageBlob);
        this.avatarPhoto = URL.createObjectURL(imageBlob);
      }
    },
    handleUpdatePartner() {
      const updatePartnerData = new UpdatePartner();
      if (this.originalLogo === this.partner.logoImage) {
        delete this.partner.logoImage;
      }
      updatePartnerData
        .execute(this.partner)
        .then(() => {
          this.$store.dispatch("showSnack", {
            message: "Os dados foram atualizados com sucesso",
            color: "green",
          });
        })
        .catch(() => {
          this.$store.dispatch("showSnack", {
            message: "Erro ao atualizar os dados tente novamente mais tarde",
            color: "red",
          });
        });
    },
  },
  mounted() {
    this.handleGetDataPartner();
    this.handleGetAllCategories();
  },
});
</script>

<style scoped>
.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.input {
  display: none;
}

@media (max-width: 768px) {
  .col-element {
    display: flex;
    justify-content: center;
  }
  .spacing {
    margin-bottom: 90px;
  }
}

.text-alterar-logo {
  background-color: #f1f1f1;
  margin-top: 2px;
  border-radius: 4px;
  color: #000;
  max-width: 117px;
  cursor: pointer;
}
</style>
